<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import Tr from '@/i18n/translation'
import { useAppStore } from '@/stores/app'
import UiEnvBanner from '@/ui/ui-env-banner.vue'
import { getBackendEnvName, getFirebaseEnvName } from '@/utils/env'
import { getSavedState, saveState } from '@/utils/local-storage'

const store = useAppStore()
const { version } = storeToRefs(store)
const { VITE_ENVIRONMENT_NAME, VITE_FAVICON_URL } = import.meta.env

const updateExists = computed(() => {
  const last = getSavedState('lastVersion')

  if (version.value?.dv)
    if (last && last < version.value.dv) return true
    else saveState('lastVersion', version.value.dv)

  return false
})

function refreshApp() {
  saveState('lastVersion', version.value?.dv)
  window.location.reload()
}

const env = computed(() => ({
  frontendEnv: VITE_ENVIRONMENT_NAME,
  backendEnv: getBackendEnvName(),
  firebaseEnv: getFirebaseEnvName(),
}))

function addEnvFavicon() {
  if (!VITE_FAVICON_URL) {
    console.error('VITE_FAVICON_URL is not set in .env.local file')
    return
  }
  const linkEl = document.createElement('link')
  linkEl.setAttribute('rel', 'icon')
  linkEl.setAttribute('href', VITE_FAVICON_URL)
  document.head.appendChild(linkEl)
}

Tr.switchLanguage(Tr.guessDefaultLocale())

addEnvFavicon()
</script>

<template>
  <UiEnvBanner v-bind="env" />
  <div v-if="updateExists" class="absolute z-30 flex w-full flex-col items-center bg-base-100 p-2">
    <button class="btn btn-primary btn-lg" @click="refreshApp">
      {{ $t('general.new-version') }}
    </button>
  </div>
  <Suspense>
    <RouterView />
  </Suspense>
</template>

<style lang="less">
@import 'nprogress/nprogress.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  // Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom.
  touch-action: manipulation;
}

html[data-theme='winter'] {
  @apply min-h-screen bg-zinc-200 bg-cover;
  background: url('@/assets/bg.png') no-repeat fixed center center;
}

html[data-theme='business'] {
  @apply min-h-screen bg-zinc-800 bg-cover;
  background: url('@/assets/bg-dark.png') no-repeat fixed center center;
}

.text-shadow {
  color: white; // TODO: black text with black shadow looks blurry, improve this
  text-shadow: 1px 1px 2px black;
}

.my-overlay {
  @apply fixed left-0 top-0 h-screen w-screen bg-zinc-800/30 dark:bg-zinc-700/60;
}

h1,
.h1 {
  @apply flex items-center gap-2 font-mon text-2xl font-semibold;
}

h2,
.h2 {
  @apply flex items-center gap-2 font-mon text-xl font-medium;
}

h3,
.h3 {
  @apply flex items-center gap-2 font-mon text-lg;
}

h4,
.h4 {
  @apply flex items-center gap-2 font-sans text-base;
}

.slide-enter-active {
  animation: slide-from-below 200ms cubic-bezier(0.77, 0, 0.175, 1);
}
.slide-leave-active {
  animation: slide-from-below 200ms cubic-bezier(0.77, 0, 0.175, 1) reverse;
}
@keyframes slide-from-below {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-y-enter-active {
  animation: fade-y-in 0.3s ease-in-out;
}

@keyframes fade-y-in {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-y-leave-active {
  animation: fade-y-out 0.1s ease-out;
}

@keyframes fade-y-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.5;
    transform: translateY(5px);
  }
}

.fade-enter-active {
  animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-leave-active {
  animation: fade-out 0.1s ease-out;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
</style>
